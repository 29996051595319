/* imported from ../../../../../meepshop/utils/styles/variables.less  */

/** ignore meepshop id */
@import '../../../../../meepshop/utils/styles/~antd/lib/style/themes/default.less';
@text-color: rgba(0, 0, 0, 0.65);
@border-radius: 4px;
@border-radius-base: 4px;
@border-color-split: #e8e8e8;

@btn-text-shadow: none;
@btn-shadow: none;
@btn-primary-shadow: none;

@checkbox-check-bg: transparent;

/* imported from loginForm.less  */

#meepshop {

.store-login__loginForm__root {
  .ant-form-item {
    margin-bottom: 14px;
  }
}

.store-login__loginForm__optionsWrapper {
  display: flex;
  justify-content: space-between;
  padding-top: 2px;

  > div {
    cursor: pointer;
  }
}

.store-login__loginForm__login {
  display: block;
  width: 100%;
  margin-top: 30px;
}

.store-login__loginForm__divider {
  font-size: 12px;
  margin: 48px 0 12px;
}

.store-login__loginForm__lineLoginButton {
  flex: 1;
}

.store-login__loginForm__fbLoginButton {
  flex: 1;
  width: 100%;
  color: #fff;
  background: #3b5998;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  letter-spacing: 0.5px;

  .anticon {
    font-size: 0;

    & + span {
      margin-left: 10px;
    }

    svg {
      font-size: 16px;
    }
  }

  span {
    vertical-align: middle;
  }
}

.store-login__loginForm__buttonGroup {
  display: flex;

  > * {
    margin-top: 20px;

    &:not(:first-child) {
      margin-left: 12px;
    }
  }

  @media (max-width: @screen-sm-max) {
    flex-direction: column;

    > *:not(:first-child) {
      margin-left: 0px;
    }
  }
}
 }
@font-family: "Noto Sans TC", "PingFang TC", "Microsoft JhengHei", "Helvetica Neue", "Helvetica", "source-han-sans-traditional", "Arial", "sans-serif";@font-family-no-number: "Noto Sans TC", "PingFang TC", "Microsoft JhengHei", "Helvetica Neue", "Helvetica", "source-han-sans-traditional", "Arial", "sans-serif";