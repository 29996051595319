/*  imported from index.less  */

#meepshop { .meepshop-line__index__root {
  background-color: #02c200;
  color: #fff;
  border: none;
  border-radius: 4px;

  .anticon {
    font-size: 20px;
    vertical-align: middle;
  }
}
 }
@font-family: "Noto Sans TC", "PingFang TC", "Microsoft JhengHei", "Helvetica Neue", "Helvetica", "source-han-sans-traditional", "Arial", "sans-serif";@font-family-no-number: "Noto Sans TC", "PingFang TC", "Microsoft JhengHei", "Helvetica Neue", "Helvetica", "source-han-sans-traditional", "Arial", "sans-serif";